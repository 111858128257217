// @codekit-prepend "../../node_modules/js-cookie/dist/js.cookie.min.js"
// @codekit-prepend "../../node_modules/magnific-popup/dist/jquery.magnific-popup.min.js"
// @codekit-prepend "../../node_modules/slick-carousel/slick/slick.min.js"

(function ($) {
  /* Breakpoints */
  var breakpoints = {
    large: 1200,
    medium: 992,
    small: 768,
    xsmall: 767,
    nav: 1201,
  };

  /* Check window width */
  var resizeTimeout = null;
  var cachedWindowWidth = $(window).width();

  // Check if window width has actually changed
  function checkWindowWidth() {
    var newWindowWidth = $(window).width();

    if (newWindowWidth !== cachedWindowWidth) {
      // If changed call functions on resize
      cachedWindowWidth = newWindowWidth;
    }
  }
  $(window).on("resize", function () {
    if (resizeTimeout !== null) {
      clearTimeout(resizeTimeout);
    }

    resizeTimeout = setTimeout(checkWindowWidth, 50);
  });

  /* Fix ADA errors/issues */
  // function adaFixes() {

  // }
  // adaFixes();

  /* Format phone numbers */
  // Strip non-numeric characters from phone numbers and add to href if element contains `tel` class
  function formatPhoneNumbers() {
    var phoneNumber;
    var phoneNumbers = $(".tel");
    var extension = $(".tel + .ext");

    phoneNumbers.each(function () {
      var $this;

      // Remove '1-' prefix before formatting if it exists
      if ($(this).text().substr(0, 2) === "1-") {
        $this = $(this).text().replace("1-", "");
      } else {
        $this = $(this).text();
      }

      // Set phone number, considering extension if it exists
      if (extension.length) {
        phoneNumber =
          "tel:1" + $this.replace(/\D/g, "") + ";ext=" + extension.data("ext");
      } else {
        phoneNumber = "tel:1" + $this.replace(/\D/g, "");
      }

      $(this).attr("href", phoneNumber);
    });
  }
  formatPhoneNumbers();

  /* Format email addresses */
  // Add email addresses to href if element contains `eml` class
  function formatEmailAddresses() {
    var emailAddress;
    var emailAddresses = $(".eml");

    emailAddresses.each(function () {
      emailAddress = "mailto:" + $(this).text();
      $(this).attr("href", emailAddress);
    });
  }
  formatEmailAddresses();

  /* Header */
  /* Sticky Navigation */
  function stickyNavigation() {
    var didScroll;
    var lastScrollTop = 0;
    var fullHeader = $(".site-header");
    var fullHeaderClasses = fullHeader.attr("class");
    var mainHeader = $(".site-header > .main");
    // var mainHeaderClasses = mainHeader.attr('class');
    var fullHeaderHeight = fullHeader.outerHeight() + 2;
    var mainHeaderHeight = mainHeader.outerHeight();
    var topHeaderHeight = $(".site-header > .top").outerHeight();
    var delta = fullHeaderHeight / 2;

    // Add padding to entry header so the header doesn't cover it
    var mainSiteEntryHeader = $(".site-main > .entry-header");
    mainSiteEntryHeader.css("padding-top", fullHeaderHeight + "px");

    // Detect scroll
    $(window).scroll(function (e) {
      didScroll = true;
    });

    setInterval(function () {
      if (didScroll) {
        hasScrolled();
        didScroll = false;
      }
    }, 100);

    function hasScrolled() {
      var st = $(this).scrollTop();

      // Make sure they scroll more than delta
      if (Math.abs(lastScrollTop - st) <= delta) {
        return;
      }

      if (st > lastScrollTop && st > mainHeaderHeight) {
        // Scroll Down
        fullHeader.addClass("sticky");
        $("body").addClass("sticky-active");
        fullHeader.css({
          transform: "translateY(-" + (fullHeaderHeight + 12) + "px)",
        });
      } else if (st < delta) {
        // Back at top
        fullHeader.removeClass("sticky");
        fullHeader.addClass(fullHeaderClasses);
        fullHeader.css({ transform: "" });
      } else if (st < lastScrollTop) {
        // Scroll Up
        if (st + $(window).height() < $(document).height()) {
          fullHeader.css({
            transform: "translateY(-" + topHeaderHeight + "px)",
          });
        }
      }

      lastScrollTop = st;
    }
  }
  if (!$(".site-header").hasClass("not-sticky")) {
    stickyNavigation();
  }

  /* Desktop Navigation */
  // Handle large devices that show the desktop menu and need touch support as hover doesn't work (iPad Pro for example)
  function isTouchDevice() {
    return typeof window.ontouchstart !== "undefined";
  }

  if (isTouchDevice() && cachedWindowWidth >= breakpoints.nav) {
    var mainNavTopLevelLinks = $(
      ".site-header .main .navigation #menu-primary > .menu-item > a"
    );
    mainNavTopLevelLinks.click(function (e) {
      mainNavTopLevelLinks.not(this).removeClass("clicked");
      $(this).toggleClass("clicked");
      if ($(this).hasClass("clicked")) {
        e.preventDefault();
      }
    });
  }

  /* Mobile Navigation */
  $(".mobile-nav-btn, .mobile-nav-overlay").on("click", function () {
    $("body").toggleClass("show-menu");
    $(".mobile-nav-btn").toggleClass("is-open");
    $(".menu-item-has-children").removeClass("show-sub-menu");
  });

  // Sub menus
  $(".site-header .main .navigation .menu-item-has-children > a").on(
    "click",
    function (e) {
      if ($(window).width() < breakpoints.nav) {
        e.stopPropagation();
        e.preventDefault();

        var parentMenu = $(this)[0].outerHTML;

        $(this).parent().toggleClass("show-sub-menu");

        if (!$(this).parent().hasClass("has-back-btn")) {
          $(this)
            .next(".sub-menu")
            .prepend('<li class="sub-menu-parent">' + parentMenu + "</li>")
            .prepend('<li class="sub-menu-back"><a href="#">Back</a></li>');
          $(this).parent().addClass("has-back-btn");
        }
      }
    }
  );

  // Click function for inserted back button
  $(".site-header .main .navigation .sub-menu").on(
    "click",
    "> .sub-menu-back",
    function (e) {
      e.preventDefault();
      $(this).parent().parent().toggleClass("show-sub-menu");
    }
  );

  /* Popups */
  // Image popup
  $(".open-image-link").magnificPopup({
    closeBtnInside: true,
    closeMarkup: '<span class="icon-close mfp-close" title="Close"></span>',
    type: "image",
  });

  // Video popup
  $(".open-video-link").magnificPopup({
    // closeBtnInside: true,
    closeMarkup: '<span class="icon-close mfp-close" title="Close"></span>',
    preloader: false,
    removalDelay: 100,
    type: "iframe",
  });

  // Schedule Tour popup
  $(".schedule-tour-form-link").magnificPopup({
    callbacks: {
      open: function () {
        // Add class to mfp-container element for css
        var popup = $(".schedule-tour-popup");

        if (popup.length) {
          popup.parent().parent().addClass("mfp-schedule-tour");
        }
      },
    },
    closeBtnInside: true,
    closeMarkup: '<span class="icon-close mfp-close" title="Close"></span>',
    items: {
      src: ".schedule-tour-popup",
    },
    mainClass: "mfp-fade",
    removalDelay: 200,
    type: "inline",
  });

  function siteWidePopup() {
    // Site-wide popup
    var siteWidePopupViewed = Cookies.get("site-wide-popup-viewed");

    // Check for site-wide popup viewed cookie, if cookie doesn't exist show popup
    if (siteWidePopupViewed == undefined) {
      // console.log("Cookie doesn't exist, show popup!");

      setTimeout(function () {
        $.magnificPopup.open({
          callbacks: {
            open: function () {
              // Add class to mfp-container element for css
              var popup = $(".site-wide-popup");

              if (popup.length) {
                popup.parent().parent().addClass("mfp-site-wide-popup");
              }
            },
          },
          closeBtnInside: true,
          closeMarkup:
            '<span class="icon-close mfp-close" title="Close"></span>',
          items: {
            src: ".site-wide-popup",
          },
          mainClass: "mfp-fade",
          removalDelay: 200,
          type: "inline",
        });
      }, 2000);

      // Set cookie, don't show popup for 7 days
      Cookies.set("site-wide-popup-viewed", "true", { expires: 7 });

      // Otherwise cookie exists, do nothing
    } else {
      // console.log("Cookie exists, don't show popup!");
    }
  }
  if ($(".site-wide-popup").length) {
    siteWidePopup();
  }

  // Image Gallery popup
  // $('.open-image-gallery-link').each(function() {
  //   $(this).magnificPopup({
  //     callbacks: {
  //       buildControls: function() {
  //         if (this.items.length > 1) {
  //           this.contentContainer.append(this.arrowLeft.add(this.arrowRight));
  //         }
  //       }
  //     },
  //     closeBtnInside: true,
  //     closeMarkup: '<span class="icon-close mfp-close" title="Close"></span>',
  //     delegate: '.gallery-image-link',
  //     type: 'image',
  //     gallery: {
  //       arrowMarkup: '<span class="icon-chevron-%dir% icon"></span>',
  //       enabled: true,
  //       tCounter: '',
  //       tPrev: 'Previous',
  //       tNext: 'Next',
  //     },
  //     image: {
  //       titleSrc: function(item) {
  //         var caption = item.el.attr('title');

  //         if (caption.length) {
  //           return '<div class="caption">' + caption + '</div>';
  //         } else {
  //           return '';
  //         }
  //       },
  //       verticalFit: true
  //     }
  //   });
  // });

  // Testimonial popup (ajax)
  setTimeout(function () {
    $(".open-testimonial-link").magnificPopup({
      callbacks: {
        open: function () {
          setTimeout(function () {
            // Add class to mfp-container element for css
            var testimonialPopup = $(".testimonial-popup");

            if (testimonialPopup.length) {
              testimonialPopup.parent().parent().addClass("mfp-testimonial");
            }

            // Add quotes
            var testimonialContent = $(
              ".testimonial-popup .testimonial .content"
            );
            var paragraphCount = testimonialContent.children().length;

            if (paragraphCount > 1) {
              testimonialContent.children().first().prepend("&ldquo;");
              testimonialContent.children().last().append("&rdquo;");
            } else {
              testimonialContent.children().prepend("&ldquo;");
              testimonialContent.children().append("&rdquo;");
            }
          }, 1000);
        },
        parseAjax: function (mfpResponse) {
          mfpResponse.data = $(mfpResponse.data).find("#testimonial");
        },
      },
      closeBtnInside: true,
      closeMarkup: '<span class="icon-close mfp-close" title="Close"></span>',
      mainClass: "mfp-fade",
      preloader: false,
      removalDelay: 200,
      type: "ajax",
    });
  }, 100);

  // Testimonial popup (inline)
  // setTimeout(function() {
  //   $('.open-testimonial-link-inline').on('click', function() {
  //     var testimonialPopupContent = $(this).closest('.testimonial').html();

  //     $.magnificPopup.open({
  //       callbacks: {
  //         open: function() {
  //           setTimeout(function() {
  //             // Add class to mfp-container element for css
  //             var testimonialPopup = $('.testimonial-popup');

  //             if (testimonialPopup.length) {
  //               testimonialPopup.parent().parent().addClass('mfp-testimonial');
  //             }
  //           }, 1000);
  //         },
  //       },
  //       closeBtnInside: true,
  //       closeMarkup: '<span class="icon-close mfp-close" title="Close"></span>',
  //       items: {
  //         src: '<div class="testimonial-popup popup row"><div class="testimonial col-xs-12">' + testimonialPopupContent + '</div></div>',
  //         type: 'inline'
  //     },
  //     });
  //   });
  // }, 100);

  /* Sliders */
  // Gallery slider
  function gallerySlider() {
    $(".gallery-slider.acf-block .slides").each(function () {
      $(this).slick({
        appendArrows: $(".gallery-slider.acf-block .slider-controls > .arrows"),
        // appendDots: $('.gallery-slider.acf-block .slider-controls > .dots'),
        arrows: true,
        // autoplay: true,
        autoplaySpeed: 5000,
        dots: false,
        // fade: true,
        infinite: false,
        mobileFirst: true,
        nextArrow: '<span class="icon-arrow-right icon" title="Next"></span>',
        prevArrow:
          '<span class="icon-arrow-left icon" title="Previous"></span>',
        responsive: [
          {
            breakpoint: breakpoints.small - 1,
            settings: {},
          },
        ],
        speed: 400,
      });
    });
  }
  if ($(".gallery-slider.acf-block").length) {
    gallerySlider();
  }

  // Testimonial slider
  function testimonialsSlider() {
    // Build slider
    $(".testimonials-slider .slides").slick({
      adaptiveHeight: true,
      appendArrows: $(".testimonials.acf-block .slider-controls > .arrows"),
      // appendDots: $('.testimonials.acf-block .slider-controls > .dots'),
      arrows: true,
      centerMode: true,
      centerPadding: "0px",
      // dots: true,
      infinite: false,
      mobileFirst: true,
      nextArrow: '<span class="icon-arrow-right icon" title="Next"></span>',
      prevArrow: '<span class="icon-arrow-left icon" title="Previous"></span>',
      responsive: [
        {
          breakpoint: breakpoints.medium - 1,
          settings: {
            adaptiveHeight: false,
          },
        },
      ],
      // slidesToScroll: 1,
      // slidesToShow: 1,
      speed: 500,
    });

    // Fade previous slides in and out to prevent them from appearing behind content
    if (cachedWindowWidth >= 992) {
      $(".testimonials-slider .slides").on(
        "beforeChange",
        function (event, slick, currentSlide, nextSlide) {
          console.log("Test.");
          var current = $(this).find(".slick-current");
          var currentIndex = currentSlide;
          var nextIndex = nextSlide;

          // If next slide index is greater than current slide index
          if (nextIndex > currentIndex) {
            // Fade slide out
            current.addClass("fade");
          } else {
            // Fade slide in
            current.prev().removeClass("fade");
          }
        }
      );
    }
  }
  if ($(".testimonials-slider").length) {
    testimonialsSlider();
  }

  // Testimonials
  // Remove empty p tags
  $(".testimonial p:empty").remove();

  // Add quotes
  var testimonialSlideExcerpts = $(
    ".testimonials-slider .testimonial .excerpt, .testimonial-popup .testimonial .content"
  );

  testimonialSlideExcerpts.each(function () {
    var paragraphCount = $(this).children().length;

    if (paragraphCount > 1) {
      $(this).children().first().prepend("&ldquo;");
      $(this).children().last().append("&rdquo;");
    } else {
      $(this).children().prepend("&ldquo;");
      $(this).children().append("&rdquo;");
    }
  });

  // List or single
  if ($(".testimonials-list").length || $(".single-testimonial").length) {
    // Remove excerpt in testimonial list
    $(".testimonial.no-excerpt .review .excerpt").remove();
  }

  // Slider
  if ($(".testimonials-slider").length) {
    $(".testimonial").each(function () {
      var testimonialSliderContent = $(this).find(".content");
      var testimonialSliderExcerpt = $(this).find(".excerpt > p");
      var testimonialSliderExcerptReadMore = $(this).find(
        ".excerpt > p.read-more"
      );
      var testimonialPermalink = $(this).data("permalink");

      // If excerpt exists remove content div
      if (testimonialSliderExcerpt.length) {
        // Remove content
        testimonialSliderContent.remove();

        // Append Permalink
        if (testimonialSliderExcerptReadMore.length) {
          testimonialSliderExcerpt.append(
            ' <a href="' +
              testimonialPermalink +
              '" class="open-testimonial-link" title="">Read More</a>'
          );
        }
      }
    });
  }

  // Sticky Forms
  // Move form into sidebar on desktop and inline on mobile
  function moveForm() {
    var sidebarStickyElement = $(".entry-content .sidebar .sticky-inner");

    // Temp element we can use to add the form back to the original location
    var formInitialLocation = $(".form-initial-location");

    // Detach and add form to variable
    var form = $(".form.acf-block.move-to-sidebar").detach();

    if ($(window).width() > 767) {
      sidebarStickyElement.append(form);
    } else {
      formInitialLocation.after(form);
    }
  }

  // Toggle 'anchor-to-form' element when scrolled above or below form on mobile
  function toggleAnchorToFormButton() {
    var formBlock = $(".form.acf-block.move-to-sidebar");
    var formBlockBottomOffSet =
      formBlock.offset().top + formBlock.outerHeight();
    var anchorToFormEl = $(".anchor-to-form");

    if ($(window).width() < 768) {
      $(window).on("scroll", function () {
        if ($(this).scrollTop() > formBlockBottomOffSet) {
          anchorToFormEl.css("right", "0");
        } else {
          anchorToFormEl.css("right", "-100%");
        }
      });
    }
  }
  if (
    $(".entry-content.no-sidebar").length == 0 &&
    $(".form.acf-block.move-to-sidebar").length
  ) {
    moveForm();
    toggleAnchorToFormButton();
  }

  // FAQ
  function faqAnswerToggle() {
    var faqQuestions = $(".faq-wrapper .item .question-wrapper");

    faqQuestions.click(function (e) {
      // If answer already open, close it
      if ($(this).hasClass("open")) {
        $(this).removeClass("open");

        // Otherwise...
      } else {
        // Close all answers
        // faqQuestions.removeClass('open');

        // Open this questions answer only
        $(this).addClass("open");
      }
    });
  }
  if ($(".faq.acf-block").length) {
    faqAnswerToggle();
  }
})(jQuery);
